import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { motion } from "framer-motion";
import axios from 'axios';
import { ClipLoader } from 'react-spinners';
import { FaDownload, FaPalette, FaShare } from 'react-icons/fa';
import { Capacitor } from '@capacitor/core';
import Playground from './Playground';

const ColorBox = ({ color, name }) => (
  <div className="flex flex-col items-center">  
    <div
      className="w-20 h-20 rounded-lg shadow-lg transform transition-transform duration-300 hover:scale-110 relative"
      style={{ backgroundColor: color }}
    >
      <span className="absolute bottom-1 right-1 text-white text-xs font-mono bg-black bg-opacity-50 px-1 rounded">
        {color}
      </span>
    </div>
    <span className="mt-2 text-sm font-medium">{name}</span>
  </div>
);

const ImageWithDownload = ({ src, alt }) => {
  const [isHovered, setIsHovered] = useState(false);

  const handleDownload = () => {
    const link = document.createElement('a');
    link.href = src;
    link.download = alt.replace(/\s+/g, '_').toLowerCase() + '.jpg';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <div 
      className="relative"
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <img
        src={src}
        alt={alt}
        className="w-full h-auto rounded-lg shadow-lg"
      />
      {isHovered && (
        <button
          onClick={handleDownload}
          className="absolute top-2 right-2 bg-primary text-white p-2 rounded-full hover:bg-secondary transition-colors duration-200"
        >
          <FaDownload />
        </button>
      )}
    </div>
  );
};

const ColorSection = ({ title, description, colors, pics, isReversed, isMobile }) => {
  const gradientStyle = {
    backgroundImage: `linear-gradient(to right, ${colors[0]?.color_code}, ${colors[2]?.color_code})`,
    WebkitBackgroundClip: "text",
    WebkitTextFillColor: "transparent",
  };

  const content = (
    <>
      <motion.h3
        initial={{ opacity: 0, x: -50 }}
        animate={{ opacity: 1, x: 0 }}
        transition={{ duration: 0.6, delay: 0.2 }}
        className="text-5xl font-bold mb-4"
        style={gradientStyle}
      >
        {title}
      </motion.h3>
      <motion.div
        initial={{ opacity: 0, x: -50 }}
        animate={{ opacity: 1, x: 0 }}
        transition={{ duration: 0.6, delay: 0.4 }}
        className="text-gray-300 text-lg leading-relaxed mb-8"
      >
        {description.split('. ').map((sentence, index, array) => (
          <p key={index} className="mb-2">
            {sentence.trim() + (index < array.length - 1 ? '.' : '')}
          </p>
        ))}
      </motion.div>
      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.6, delay: 0.6 }}
        className="flex justify-around mb-8"
      >
        {Array.isArray(colors) && colors.map((color, index) => (
          <ColorBox key={index} color={color.color_code} name={color.color_name} />
        ))}
      </motion.div>
    </>
  );

  const images = (
    <motion.div
      initial={{ opacity: 0, x: 50 }}
      animate={{ opacity: 1, x: 0 }}
      transition={{ duration: 0.6, delay: 0.4 }}
      className={`grid ${isMobile ? 'grid-cols-1' : 'grid-cols-2'} gap-4 h-full`}
    >
      {pics && Object.values(pics).map((pic, index) => (
        <ImageWithDownload
          key={index}
          src={pic}
          alt={`${title} example ${index + 1}`}
          className={`w-full ${isMobile ? 'h-[300px]' : 'h-full'} object-cover`}
        />
      ))}
    </motion.div>
  );

  if (isMobile) {
    return (
      <motion.div
        initial={{ opacity: 0, y: 50 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.8 }}
        className="mb-12"
      >
        {content}
        <div className="mt-8">{images}</div>
      </motion.div>
    );
  }

  return (
    <motion.div
      initial={{ opacity: 0, y: 50 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.8 }}
      className={`flex flex-col lg:flex-row items-start mb-24 ${
        isReversed ? "lg:flex-row-reverse" : ""
      }`}
    >
      <div className={`lg:w-1/3 mb-8 lg:mb-0 ${isReversed ? 'lg:pl-12' : 'lg:pr-12'}`}>
        {content}
      </div>
      <div className="lg:w-2/3 mb-8 lg:mb-0 h-[600px]">
        {images}
      </div>
    </motion.div>
  );
};

const JewelrySection = ({ jewelColor, explanation, pics, isMobile }) => {
  return (
    <motion.div
      initial={{ opacity: 0, y: 50 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.8 }}
      className="mt-12 text-center"
    >
      <h3 className="text-4xl font-bold mb-4 bg-gradient-to-r from-yellow-400 to-yellow-200 bg-clip-text text-transparent">
        {jewelColor === 'gold' ? 'Gold Jewelry' : 'Silver Jewelry'}
      </h3>
      <div className="text-gray-300 text-lg leading-relaxed mb-8 max-w-3xl mx-auto">
        {explanation.split('. ').map((sentence, index, array) => (
          <p key={index} className="mb-2">
            {sentence.trim() + (index < array.length - 1 ? '.' : '')}
          </p>
        ))}
      </div>
      <div className={`grid ${isMobile ? 'grid-cols-1' : 'grid-cols-2'} gap-4 max-w-4xl mx-auto ${isMobile ? '' : 'h-[600px]'}`}>
        {pics && Object.values(pics).map((pic, index) => (
          <ImageWithDownload
            key={index}
            src={pic}
            alt={`${jewelColor} jewelry example ${index + 1}`}
            className={`w-full ${isMobile ? 'h-[300px]' : 'h-full'} object-cover`}
          />
        ))}
      </div>
    </motion.div>
  );
};

export const Separator = () => (
  <div className="w-full flex justify-center my-8">
    <div className="w-1/3 h-px bg-gradient-to-r from-transparent via-gray-400 to-transparent"></div>
  </div>
);

const ColorAnalysisTest = () => {
  const [scrollY, setScrollY] = useState(0);
  const [playbook, setPlaybook] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  const { publicId } = useParams();
  const [generationStatus, setGenerationStatus] = useState('idle');
  const [isPublicView, setIsPublicView] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const [isNativeMobile, setIsNativeMobile] = useState(false);

  useEffect(() => {
    const checkMobile = () => {
      setIsMobile(window.innerWidth <= 768);
    };
    checkMobile();
    window.addEventListener('resize', checkMobile);
    return () => window.removeEventListener('resize', checkMobile);
  }, []);

  useEffect(() => {
    setIsNativeMobile(Capacitor.isNativePlatform());
  }, []);

  useEffect(() => {
    const handleScroll = () => setScrollY(window.scrollY);
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  useEffect(() => {
    const fetchPlaybook = async () => {
      setLoading(true);
      try {
        let response;
        if (publicId) {
          // Fetch public playbook
          response = await axios.get(
            `${process.env.REACT_APP_API_GATEWAY_ENDPOINT}/create-playbook`,
            { params: { public_id: publicId } }
          );
          setIsPublicView(true);
        } else {
          // Fetch private playbook
          const token = localStorage.getItem('token');
          if (!token) {
            navigate('/login');
            return;
          }

          // First, verify the token
          const verifyResponse = await axios.post(
            `${process.env.REACT_APP_API_GATEWAY_ENDPOINT}/verify-token`,
            { 
              token,
              appleToken: isNativeMobile ? localStorage.getItem('appleToken') : undefined
            },
            {
              headers: {
                'Content-Type': 'application/json'
              }
            }
          );

          if (!verifyResponse.data.valid) {
            localStorage.removeItem('token');
            navigate('/login');
            return;
          }
          if (!verifyResponse.data.hasPaid) {
            navigate('/payment');
            return;
          }
          if (verifyResponse.data.nextPage === '/waiting') {
            navigate('/waiting');
            return;
          }
          if (verifyResponse.data.nextPage === '/upload') {
            navigate('/upload');
            return;
          }

          const uuid = verifyResponse.data.uuid;

          // If token is valid, fetch the playbook
          response = await axios.get(
            `${process.env.REACT_APP_API_GATEWAY_ENDPOINT}/create-playbook`,
            {
              params: { token, uuid }
            }
          );

          if (response.data.message === 'Playbook generation started') {
            setGenerationStatus('generating');
            pollPlaybookStatus(uuid);
          }
        }

        if (response.data.playbook) {
          setPlaybook(response.data.playbook);
          setGenerationStatus('completed');
        } else {
          setError('Playbook not found');
        }
      } catch (error) {
        // console.error('Error:', error);
        // setError('An error occurred. Please try again.');
        navigate('/login');
      } finally {
        setLoading(false);
      }
    };

    fetchPlaybook();
  }, [navigate, publicId, isNativeMobile]);

  const pollPlaybookStatus = async (uuid) => {
    const pollInterval = setInterval(async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_GATEWAY_ENDPOINT}/create-playbook`,
          {
            params: { token: localStorage.getItem('token'), uuid }
          }
        );

        if (response.data.playbook) {
          setPlaybook(response.data.playbook);
          setGenerationStatus('completed');
          clearInterval(pollInterval);
        }
      } catch (error) {
        console.error('Error polling playbook status:', error);
      }
    }, 10000); // Poll every 10 seconds

    // Clear interval after 10 minutes (60 * 10 * 1000 ms)
    setTimeout(() => {
      clearInterval(pollInterval);
      if (generationStatus !== 'completed') {
        setError('Playbook generation timed out. Please try again later.');
      }
    }, 600000);
  };

  const pageVariants = {
    initial: { opacity: 0, y: 20 },
    in: { opacity: 1, y: 0 },
    out: { opacity: 0, y: -20 }
  };

  const pageTransition = {
    type: "tween",
    ease: "anticipate",
    duration: 0.5
  };

  const ShareButton = () => {
    const [copied, setCopied] = useState(false);

    const handleShare = () => {
      if (playbook && playbook.public_id) {
        const shareUrl = `https://palettehunt.com/shared/${playbook.public_id}`;
        navigator.clipboard.writeText(shareUrl);
        setCopied(true);
        setTimeout(() => setCopied(false), 2000);
      }
    };

    return (
      <button
        onClick={handleShare}
        className="bg-white text-gray-900 font-semibold py-3 px-8 rounded-full hover:bg-gray-100 transition duration-300 ease-in-out transform hover:scale-105 shadow-lg flex items-center justify-center space-x-2"
      >
        <FaShare className="mr-2" />
        <span>{copied ? 'Copied!' : 'Share Your Color Analysis'}</span>
      </button>
    );
  };

  if (loading) {
    return (
      <div className="flex items-center justify-center min-h-screen bg-gradient-to-br from-gray-900 to-gray-800">
        <ClipLoader color="#ffffff" loading={loading} size={50} />
      </div>
    );
  }

  if (error) {
    return (
      <div className="flex items-center justify-center min-h-screen bg-gradient-to-br from-gray-900 to-gray-800 text-white">
        <h1 className="text-3xl font-bold">{error}</h1>
      </div>
    );
  }

  if (generationStatus === 'generating') {
    return (
      <div className="flex items-center justify-center min-h-screen bg-gradient-to-br from-gray-900 to-gray-800 text-white">
        <h1 className="text-3xl font-bold">Generating your color analysis... This may take a few minutes.</h1>
      </div>
    );
  }

  if (!playbook || !playbook.style_analysis) {
    return (
      <div className="flex items-center justify-center min-h-screen bg-gradient-to-br from-gray-900 to-gray-800 text-white">
        <h1 className="text-3xl font-bold">Error: Playbook data is missing or invalid.</h1>
      </div>
    );
  }

  return (
    <motion.div
      initial="initial"
      animate="in"
      exit="out"
      variants={pageVariants}
      transition={pageTransition}
      className={`bg-gray-900 text-white min-h-screen ${isNativeMobile ? 'pt-16 pb-32' : ''}`}
    >
      <div>
        {!isNativeMobile && (
          <header
            className="w-full p-6 bg-gray-900 bg-opacity-90 backdrop-filter backdrop-blur-lg"
            style={{
              boxShadow: `0 4px 6px -1px rgba(0, 0, 0, ${Math.min(
                scrollY / 1000,
                0.1
              )})`,
            }}
          >
            <div className="max-w-7xl mx-auto flex justify-between items-center">
              <Link to="/" className="flex items-center">
                <img
                  src="/logo.png"
                  alt="Palette Hunt Logo"
                  className="h-16 w-auto mr-4"
                />
              </Link>
              {!isPublicView && (
                <Link
                  to="/playground"
                  className="bg-primary text-white font-semibold py-2 px-4 rounded-full hover:bg-opacity-90 transition duration-300 ease-in-out transform hover:scale-105 shadow-lg flex items-center"
                >
                  <FaPalette className="mr-2" /> Color Playground
                </Link>
              )}
            </div>
          </header>
        )}
        <main className={`p-8 ${isNativeMobile ? 'mt-8 mb-16' : ''}`}>
          <div className="max-w-7xl mx-auto">
            <motion.h1
              initial={{ opacity: 0, y: -50 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.8 }}
              className="text-6xl font-bold mb-8 text-center bg-gradient-to-r from-primary to-secondary text-transparent bg-clip-text"
            >
              {isPublicView ? "Color Analysis Results" : "Your Color Analysis Results"}
            </motion.h1>

            {/* Add the introduction section */}
            {playbook && playbook.style_analysis && playbook.style_analysis.introduction && (
              <>
                <motion.div
                  initial={{ opacity: 0, y: -30 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ duration: 0.8, delay: 0.4 }}
                  className="text-xl text-gray-300 text-center mb-18 max-w-3xl mx-auto"
                >
                  {playbook.style_analysis.introduction.split('. ').map((sentence, index, array) => (
                    <p key={index} className="mb-4">
                      {sentence.trim() + (index < array.length - 1 ? '.' : '')}
                    </p>
                  ))}
                </motion.div>
                <Separator />
              </>
            )}

            <div className="mt-24">
              {Array.isArray(playbook.style_analysis.color_analysis) && playbook.style_analysis.color_analysis.map((section, index) => (
                <React.Fragment key={index}>
                  {index === 0 && <div className="mb-16" />}
                  <ColorSection
                    title={section.shades}
                    description={section.explanation}
                    colors={section.colors_and_codes}
                    pics={section.pics}
                    isReversed={index % 2 !== 0}
                    isMobile={isMobile}
                  />
                  {index < playbook.style_analysis.color_analysis.length - 1 && (
                    <>
                      <div className="mt-4" />
                      <Separator />
                      <div className="mb-24" />
                    </>
                  )}
                </React.Fragment>
              ))}
            </div>

            {playbook.style_analysis.silver_or_gold && (
              <>
                <div className="mt-8" />
                <Separator />
                <div className="mb-16" />
                <motion.h2
                  initial={{ opacity: 0, y: -50 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ duration: 0.8 }}
                  className="text-5xl font-bold mb-6 text-center"
                  style={{
                    background: 'linear-gradient(to right, #C0C0C0, #FFD700)',
                    WebkitBackgroundClip: 'text',
                    WebkitTextFillColor: 'transparent',
                    backgroundClip: 'text',
                    textFillColor: 'transparent'
                  }}
                >
                  Silver or Gold?
                </motion.h2>
                <JewelrySection
                  jewelColor={playbook.style_analysis.silver_or_gold.jewel_color}
                  explanation={playbook.style_analysis.silver_or_gold.explanation}
                  pics={playbook.style_analysis.silver_or_gold.pics}
                  isMobile={isMobile}
                />
              </>
            )}
            
            {/* Add Share button and Color Playground button at the bottom */}
            {!isPublicView && (
              <div className="max-w-4xl mx-auto mt-2 px-4 space-y-16">
                <div className="text-center space-y-6">
                  <h3 className="text-3xl font-semibold text-white">
                    Want to share your color analysis with friends?
                  </h3>
                  <p className="text-xl text-gray-300">
                    Let others see your personalized color palette and style recommendations!
                  </p>
                  <div className="flex justify-center mt-16"> {/* Added mt-8 for extra space */}
                    <ShareButton />
                  </div>
                </div>
                
                <div className="text-center space-y-6">
                  <h3 className="text-3xl font-semibold text-white">
                    Ready to explore your new style?
                  </h3>
                  <p className="text-xl text-gray-300">
                    Try on different outfits and colors in our virtual studio!
                  </p>
                  <Link
                    to="/playground"
                    className="bg-gradient-to-r from-primary to-secondary text-white font-semibold py-4 px-8 rounded-full hover:opacity-90 transition-all duration-300 ease-in-out transform hover:scale-105 shadow-lg inline-block text-xl"
                  >
                    Go to Color Playground
                  </Link>
                </div>
              </div>
            )}
          </div>
        </main>
      </div>
      {!isNativeMobile && (
        <footer className="bg-gray-800 text-white py-8 mt-16">
          <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
            <div className="flex flex-col md:flex-row justify-between items-center">
              <div className="flex items-center mb-4 md:mb-0">
                <img
                  src="/logo.png"
                  alt="Palette Hunt Logo"
                  className="h-12 w-auto mr-4"
                />
                <span className="text-2xl font-bold">Palette Hunt</span>
              </div>
              <div className="flex space-x-6">
                <Link to="/about" className="hover:text-primary transition-colors">
                  About
                </Link>
                <Link to="/contact" className="hover:text-primary transition-colors">
                  Contact
                </Link>
                <Link to="/privacy" className="hover:text-primary transition-colors">
                  Privacy Policy
                </Link>
              </div>
            </div>
            <div className="mt-8 text-center text-gray-400">
              © {new Date().getFullYear()} Palette Hunt. All rights reserved.
            </div>
          </div>
        </footer>
      )}
    </motion.div>
  );
};

export { ColorSection, JewelrySection, ImageWithDownload };

export default ColorAnalysisTest;