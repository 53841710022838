import React, { useState, useEffect } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import axios from 'axios';
import { CheckCircle2 } from 'lucide-react';
import { ClipLoader } from 'react-spinners';
import { Capacitor } from '@capacitor/core';
import { Stripe, ApplePayEventsEnum, PaymentSheetEventsEnum } from '@capacitor-community/stripe';

const Credits = () => {
  const [selectedPlan, setSelectedPlan] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isProcessing, setIsProcessing] = useState(false);
  const [error, setError] = useState(null);
  const [isNativeMobile, setIsNativeMobile] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    setIsNativeMobile(Capacitor.isNativePlatform());
  }, []);

  useEffect(() => {
    const verifyToken = async () => {
      const token = localStorage.getItem('token');
      if (!token) {
        navigate('/login');
        return;
      }

      try {
        const response = await axios.post(
          `${process.env.REACT_APP_API_GATEWAY_ENDPOINT}/verify-token`, 
          { 
            token,
            appleToken: isNativeMobile ? localStorage.getItem('appleToken') : undefined
          }
        );
        if (!response.data.valid) {
          navigate('/login');
        } else {
          setIsLoading(false);
        }
      } catch (error) {
        console.error('Token verification failed:', error);
        navigate('/login');
      }
    };

    verifyToken();

    if (isNativeMobile) {
      Stripe.initialize({
        publishableKey: process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY
      });
    }
  }, [navigate, isNativeMobile]);

  const creditPlans = [
    { 
      id: 'basic_credits', 
      name: 'Basic Bundle', 
      price: '$5',
      credits: 70,
      discount: 0
    },
    { 
      id: 'pro_credits', 
      name: 'Pro Bundle', 
      price: '$15',
      credits: 300,
      discount: 20
    },
    { 
      id: 'premium_credits', 
      name: 'Premium Bundle', 
      price: '$40',
      credits: 1000,
      discount: 30
    }
  ];

  const handlePlanSelection = async (planId) => {
    setSelectedPlan(planId);
    setIsProcessing(true);
    setError(null);

    const token = localStorage.getItem('token');
    const appleToken = isNativeMobile ? localStorage.getItem('appleToken') : undefined;

    if (!token && !appleToken) {
      navigate('/login');
      return;
    }

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_GATEWAY_ENDPOINT}/credits-checkout`,
        { token, appleToken, plan: planId, platform: isNativeMobile ? 'mobile' : 'web' },
        {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
          },
          withCredentials: true
        }
      );

      if (isNativeMobile) {
        const { clientSecret, ephemeralKey, customerId, publishableKey, amount, currency } = response.data;

        console.log('Received data from server:', response.data);

        if (!clientSecret || !ephemeralKey || !customerId || !publishableKey) {
          throw new Error('Invalid response from server');
        }

        // Initialize Stripe with the received publishable key
        await Stripe.initialize({
          publishableKey: publishableKey
        });

        // Check if Apple Pay is available
        let isApplePayAvailable = false;
        try {
          await Stripe.isApplePayAvailable();
          isApplePayAvailable = true;
          console.log('Apple Pay is available');
        } catch (error) {
          console.log('Apple Pay is not available:', error);
        }

        if (isApplePayAvailable) {
          try {
            await Stripe.createApplePay({
              paymentIntentClientSecret: clientSecret,
              paymentSummaryItems: [
                { label: 'Palette Hunt Credits', amount: amount / 100 }
              ],
              merchantIdentifier: process.env.REACT_APP_MERCHANT_ID,
              countryCode: 'US',
              currency: currency
            });

            // Add listener for Apple Pay events
            Stripe.addListener(ApplePayEventsEnum.Completed, () => {
              console.log('Apple Pay completed');
              navigate('/playground');
            });

            const result = await Stripe.presentApplePay();
            console.log('Apple Pay result:', result);

            // The result handling is now done in the event listeners above
          } catch (error) {
            console.error('Error presenting Apple Pay:', error);
            setError('Error presenting Apple Pay. Please try again.');
          }
        } else {
          console.log('clientSecret:', clientSecret);
          console.log('customerId:', customerId);
          console.log('ephemeralKey:', ephemeralKey);
          console.log('merchantDisplayName:', 'Palette Hunt');

          // Fallback to regular Stripe payment sheet
          const initResult = await Stripe.createPaymentSheet({
            paymentIntentClientSecret: clientSecret,
            customerId: customerId,
            customerEphemeralKeySecret: ephemeralKey,
            merchantDisplayName: 'Palette Hunt',
          });

          console.log('Payment sheet initialized:', initResult);

          try {
            const { paymentResult } = await Stripe.presentPaymentSheet();
            console.log('Payment result:', paymentResult);
            Stripe.addListener(PaymentSheetEventsEnum.Completed, () => {
              console.log('Payment completed successfully');
              navigate('/playground');
            });
          } catch (presentError) {
            console.error('Error presenting payment sheet:', presentError);
            setError('An error occurred while presenting the payment sheet. Please try again.');
          }
        }
      } else {
        // Handle desktop payment
        if (response.data.checkoutUrl) {
          window.location.href = response.data.checkoutUrl;
        } else {
          throw new Error('Unexpected response from server');
        }
      }
    } catch (error) {
      console.error('Credits checkout error:', error);
      setError('Failed to initiate checkout. Please try again.');
    } finally {
      setIsProcessing(false);
    }
  };

  if (isLoading) {
    return (
      <div className="flex items-center justify-center min-h-screen bg-gradient-to-br from-gray-900 to-gray-800">
        <ClipLoader color="#ffffff" loading={isLoading} size={50} />
      </div>
    );
  }

  return (
    <div className={`${isNativeMobile ? 'bg-pink-100' : 'bg-gradient-to-br from-gray-900 to-gray-800'} ${isNativeMobile ? 'text-black' : 'text-white'} min-h-screen flex flex-col`}>
      <div className="container mx-auto px-4 pt-8 pb-16">
        {!isNativeMobile && (
          <Link to="/" className="mb-8 inline-block">
            <img src="/logo.png" alt="Palette Hunt Logo" className="h-16 w-auto" />
          </Link>
        )}
        
        <h1 className={`text-4xl sm:text-5xl font-bold mb-8 text-center ${isNativeMobile ? 'text-gray-800' : 'bg-gradient-to-r from-primary to-secondary text-transparent bg-clip-text'}`}>
          Recharge Your Creativity
        </h1>
        
        <p className={`text-xl ${isNativeMobile ? 'text-gray-700' : 'text-gray-300'} mb-12 text-center max-w-2xl mx-auto`}>
          Choose a credit package to continue generating stunning AI-powered images and exploring your perfect color palette.
        </p>
        
        {error && <p className="text-red-500 mb-4 text-center">{error}</p>}
        
        {isNativeMobile ? (
          <div className="space-y-4">
            {creditPlans.map((plan) => (
              <div 
                key={plan.id}
                className={`bg-white p-4 rounded-lg shadow-md ${selectedPlan === plan.id ? 'border-2 border-pink-500' : ''}`}
                onClick={() => handlePlanSelection(plan.id)}
              >
                <div className="flex justify-between items-center">
                  <div>
                    <span className="text-lg font-semibold">{plan.credits} credits</span>
                    {plan.discount > 0 && (
                      <span className="text-pink-500 text-sm ml-2">({plan.discount}% discount)</span>
                    )}
                  </div>
                  <span className="text-lg font-bold">{plan.price}</span>
                </div>
              </div>
            ))}
          </div>
        ) : (
          <div className="grid grid-cols-1 md:grid-cols-3 gap-8 mb-12 w-full max-w-6xl mx-auto">
            {creditPlans.map((plan) => (
              <div 
                key={plan.id}
                className={`bg-gray-800 p-6 rounded-xl shadow-lg cursor-pointer transition-all transform hover:scale-105 ${
                  plan.id === 'pro_credits' ? 'md:scale-110 bg-gradient-to-br from-primary to-secondary' : ''
                } ${selectedPlan === plan.id ? 'border-2 border-primary ring-4 ring-primary ring-opacity-50' : ''}`}
                onClick={() => handlePlanSelection(plan.id)}
              >
                <h2 className="text-2xl font-bold mb-4 text-white">{plan.name}</h2>
                <p className="text-4xl font-bold mb-4">{plan.price}</p>
                <ul className="space-y-2 mb-6">
                  <li className="flex items-center text-white">
                    <CheckCircle2 className="text-green-500 mr-2" size={16} />
                    <span><strong>{plan.credits} Credits</strong></span>
                  </li>
                  {plan.discount > 0 && (
                    <li className="flex items-center text-white">
                      <CheckCircle2 className="text-green-500 mr-2" size={16} />
                      <span><strong>{plan.discount}% bonus</strong> credits</span>
                    </li>
                  )}
                </ul>
                <button 
                  className={`w-full py-2 px-4 rounded-full font-semibold transition-colors ${
                    plan.id === 'pro_credits' 
                      ? 'bg-white text-primary hover:bg-gray-100' 
                      : 'bg-primary text-white hover:bg-opacity-90'
                  }`}
                  onClick={() => handlePlanSelection(plan.id)}
                  disabled={isProcessing}
                >
                  {isProcessing && selectedPlan === plan.id ? (
                    <ClipLoader color="#ffffff" loading={true} size={20} />
                  ) : (
                    'Select Plan'
                  )}
                </button>
              </div>
            ))}
          </div>
        )}
        
        <p className={`mt-8 ${isNativeMobile ? 'text-gray-700' : 'text-gray-400'} text-center max-w-2xl mx-auto`}>
          By proceeding, you agree to our <Link to="/terms-and-conditions" className={`${isNativeMobile ? 'text-pink-500' : 'text-primary'} hover:underline`}>Terms of Service</Link> and <Link to="/privacy-policy" className={`${isNativeMobile ? 'text-pink-500' : 'text-primary'} hover:underline`}>Privacy Policy</Link>.
        </p>
      </div>
    </div>
  );
};

export default Credits;